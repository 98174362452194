import React from 'react';
import slide from '../../res/img/slideOne.png'
import { useNavigate } from "react-router-dom";

const SlideOne = () => {

    const navigate = useNavigate();

    function redirectSignIn() {
        navigate("/signin");
    }
    function redirectSignUp() {
        navigate("/signup");
    }


    return (
        <div className="flex flex-col items-center dark:bg-black">
            <h2 className="text-6xl text-center font-black mt-20 max-md:text-3xl dark:text-white">Africa's No1 Mass Literacy, Skills and Professional Development Platform</h2>

            <p className="text-center text-2xl max-md:text-lg pt-4 font-bold  dark:text-white">Up your capacity development, acquire jobs and market relevant skills, basic and advanced literacy; get empowered to thrive in the emerging realities.
            Engage our AI-Coach to create quality training materials on anything and everything you desire to learn; get a certificate at the end of your learning, and begin to earn a better livelihood.
            </p>

            <p className="text-center text-black mt-6 max-w-2xl font-medium max-md:text-xs dark:text-white">“We are ambitious and treading the path to be: ‘Africa’s No1 Emerging Realities - Markets and Jobs Relevant - Online Non-formal Education, Mass Literacy, Continuous Learning, and, Skills and Professional Capacity Development Platform’. We as much as want to be a part of your future elevations and success stories, as we hope you will be part of ours. I very humbly Welcome you onboard!” - Ayo Oyeleye, CEO, ADTC.NG.</p>

            <div className="flex space-x-4 mb-4 mt-6">
                <button onClick={redirectSignIn} className="border-black text-black border px-3 py-2 font-medium dark:border-white dark:text-white">
                    SignIn
                </button>
                <button onClick={redirectSignUp} className="bg-black text-white px-3 py-2 font-medium dark:bg-white dark:text-black">
                    SignUp
                </button>
            </div>

            <img
                src={slide}
                alt="Your Alt Text"
                className="w-full max-w-screen-xl mx-auto my-10 md:my-10"
            />
        </div>
    );
};

export default SlideOne;
